import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useProfiles, useActiveAccount } from 'thirdweb/react';

import box from '../../icons/box.svg';
import home from '../../icons/home.svg';
import p_home from '../../icons/p_home.svg';
import p_box from '../../icons/p_box.svg';

import { g8wyLogo, help, sun } from '../../assets';
import { UserMenuDropdown } from '../Dropdown';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>('dashboard');
  const activeAccount = useActiveAccount();
  const { data: profiles } = useProfiles();

  const [userEmail, setUserEmail] = React.useState<string>('');

  const [toggleUserMenu, setToggleUserMenu] = useState(false);

  const handleLinkState = (state: string) => {
    setActiveTab(state);
  };

  useEffect(() => {
    if (profiles) {
      setUserEmail(profiles[0].details.email || '');
    }
  }, [profiles]);

  useEffect(() => {
    if (location.pathname === '/home') {
      setActiveTab('dashboard');
    } else if (location.pathname === '/projects') {
      setActiveTab('projects');
    } else if (location.pathname === '/contracts') {
      setActiveTab('contracts');
    } else if (location.pathname === '/financials') {
      setActiveTab('financial');
    } else if (location.pathname === '/sales') {
      setActiveTab('sales');
    }
  }, [location.pathname]);

  return (
    <nav className="flex justify-between items-center w-2/2 mx-auto bg-white sm:px-32 px-5 shadow-[0px_4px_8px_0px_rgba(203,213,225,0.25)]">
      <div>
        <Link to="/home">
          <img className="w-[302px] h-[60px]" src={g8wyLogo} alt="..." />
        </Link>
      </div>
      <div className="nav-links duration-500 w-auto hidden sm:flex items-center text-[#0F172A]">
        <ul className="flex flex-row items-center sm:gap-8 gap-3">
          <Link to="/" onClick={() => handleLinkState('dashboard')}>
            <li>
              <div className="flex hover:text-[#0F172A]">
                <img
                  src={`${activeTab === 'dashboard' ? p_home : home} `}
                  className="w-[28px] h-[28px] sm:w-[24px] sm:h-[24px] block sm:hidden"
                  alt="new_img"
                />
                <a
                  className={`hidden sm:block sm:text-[14px] sm:px-4 text-[#0F172A] ${
                    activeTab === 'dashboard'
                      ? 'font-bold underline'
                      : 'font-medium'
                  }`}
                  href="_#"
                >
                  Home
                </a>
              </div>
            </li>
          </Link>
          <Link to="/sales" onClick={() => handleLinkState('sales')}>
            <li>
              <div className="flex hover:text-[#0F172A]">
                <img
                  src={`${activeTab === 'sales' ? p_box : box} `}
                  className="w-[28px] h-[28px] sm:w-[24px] sm:h-[24px] block sm:hidden"
                  alt="new_img1"
                />
                <a
                  className={`hidden sm:block sm:text-[14px] sm:px-4 text-[#0F172A] ${
                    activeTab === 'sales'
                      ? 'font-bold underline'
                      : 'font-medium'
                  }`}
                  href="_#"
                >
                  Sales
                </a>
              </div>
            </li>
          </Link>
          <Link to="/contracts" onClick={() => handleLinkState('contracts')}>
            <li>
              <div className="flex hover:text-[#0F172A]">
                <img
                  src={`${activeTab === 'contracts' ? p_box : box} `}
                  className="w-[28px] h-[28px] sm:w-[24px] sm:h-[24px] block sm:hidden"
                  alt="new_img1"
                />
                <a
                  className={`hidden sm:block sm:text-[14px] sm:px-4 text-[#0F172A] ${
                    activeTab === 'contracts'
                      ? 'font-bold underline'
                      : 'font-medium'
                  }`}
                  href="_#"
                >
                  Contracts
                </a>
              </div>
            </li>
          </Link>
          <Link to="/financials" onClick={() => handleLinkState('financial')}>
            <li>
              <div className="flex hover:text-[#0F172A]">
                <img
                  src={`${activeTab === 'financial' ? p_box : box} `}
                  className="w-[28px] h-[28px] sm:w-[24px] sm:h-[24px] block sm:hidden"
                  alt="new_img1"
                />
                <a
                  className={`hidden sm:block sm:text-[14px] sm:px-4 text-[#0F172A] ${
                    activeTab === 'financial'
                      ? 'font-bold underline'
                      : 'font-medium'
                  }`}
                  href="_#"
                >
                  Financial
                </a>
              </div>
            </li>
          </Link>
        </ul>
      </div>
      <section className="flex flex-row gap-6 items-center">
        {!activeAccount && (
          <section>
            <nav className="flex gap-4 items-center self-stretch my-auto text-sm font-medium leading-6 text-slate-900">
              <button
                className="btn-text"
                onClick={() => navigate('/auth/login')}
              >
                Log In
              </button>
              <div className="gradient-primary">
                <button
                  className="btn-text rounded-[5px] m-[1px] pt-[7px] px-[15px]"
                  onClick={() => navigate('/auth/signup')}
                >
                  Sign Up
                </button>
              </div>
            </nav>
          </section>
        )}
        <section>
          <nav className="hidden sm:flex gap-2 items-center self-stretch my-auto">
            {activeAccount && (
              <section className="relative">
                <button
                  className="flex gap-2 justify-center items-center self-stretch px-3 my-auto w-10 h-10 rounded-[96px] bg-slate-200 text-base"
                  onClick={() => setToggleUserMenu(!toggleUserMenu)}
                >
                  {userEmail.length > 0
                    ? userEmail.slice(0, 2).toUpperCase()
                    : '0X'}
                </button>
                {toggleUserMenu && (
                  <UserMenuDropdown userAddress={activeAccount.address} />
                )}
              </section>
            )}
            <button
              className="flex gap-2 justify-center items-center self-stretch px-3 my-auto w-10 h-10 border border-solid border-slate-200 rounded-[96px]"
              onClick={() => window.open('https://docs.g8wy.app', '_blank')}
            >
              <img
                loading="lazy"
                src={help}
                alt="help"
                className="object-contain self-stretch my-auto w-4 aspect-square"
              />
            </button>
            <button className="flex gap-2 justify-center items-center self-stretch px-3 my-auto w-10 h-10 border border-solid border-slate-200 rounded-[96px]">
              <img
                loading="lazy"
                src={sun}
                alt="sun"
                className="object-contain self-stretch my-auto w-4 aspect-square"
              />
            </button>
          </nav>
        </section>
      </section>
    </nav>
  );
};

export default Navbar;
