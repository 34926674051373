import React, { useEffect, useState } from 'react';

import useModal from '../../hooks/useModal';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { AccountLinkingForm } from '../../components/Form';

interface BankAccount {
  id: string;
  accountNumber: string;
  bankName: string;
}

const BankAccounts: React.FC = () => {
  const {
    circle: { getWireBanks, getWireBankDescription },
  } = useFantomStarterAdminApi();
  const { openModal, closeModal } = useModal();

  const [bankDescriptions, setBankDescriptions] = useState<string[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const openAccountLinkFormModal = () => {
    openModal(<AccountLinkingForm onCancel={closeModal} />);
  };

  useEffect(() => {
    (async () => {
      const result = await getWireBanks();
      if (result.data) {
        const wireIds = result.data.map((item: any) => item.wire_id);
        const bankDescriptions: string[] = [];
        for (const wireId of wireIds) {
          const result = await getWireBankDescription(wireId);
          if (result.data) {
            bankDescriptions.push(result.data.description);
          }
        }
        setBankDescriptions(bankDescriptions);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bankDescriptions.length) {
      const accounts: BankAccount[] = bankDescriptions.map(
        (description, index) => {
          const accountNumberMatch = description.match(/\*{4}\d{4}$/);
          const accountNumber = accountNumberMatch ? accountNumberMatch[0] : '';

          const bankNameMatch = description.match(/^[A-Z\s]+/);
          const bankName = bankNameMatch ? bankNameMatch[0].trim() : '';

          return {
            id: (index + 1).toString(),
            accountNumber: accountNumber,
            bankName: bankName,
          };
        }
      );
      setBankAccounts(accounts);
    }
  }, [bankDescriptions]);

  return (
    <section className="flex flex-col py-2 w-full">
      <header className="flex justify-between items-center w-full">
        <h1 className="text-base font-semibold text-black">Bank Accounts</h1>
        <button
          className="px-4 py-2 text-sm font-medium text-white bg-slate-900 rounded-md"
          onClick={openAccountLinkFormModal}
        >
          Add New
        </button>
      </header>
      <div className="flex flex-wrap gap-4 mt-6 w-full">
        {bankAccounts.map((account) => (
          <article
            key={account.id}
            className="flex justify-between gap-4 p-4 bg-white rounded-md border border-solid w-[250px]"
          >
            <div className="text-sm text-slate-900">
              <h2 className="font-semibold">Account {account.id}</h2>
              <p className="mt-1">{account.accountNumber}</p>
              <p className="mt-1 text-xs text-slate-500">{account.bankName}</p>
            </div>
            {/* <div className="p-2 w-8 h-8 bg-white border-slate-200">
              <img
                loading="lazy"
                src={more}
                className="object-contain w-4"
                alt=""
              />
            </div> */}
          </article>
        ))}
      </div>
    </section>
  );
};

export default BankAccounts;
