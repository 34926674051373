import React, { useState } from 'react';

import { arrowDown } from '../../assets';

const PreferencesSection: React.FC = () => {
  const preferencesData = {
    currency: 'USD',
    language: 'English',
    timezone: '[+00:00 UTC] UTC, Universal Time',
  };

  const [isOn, setIsOn] = useState(true);
  const toggleSwitch = () => setIsOn(!isOn);

  return (
    <section className="py-2 w-full flex flex-col justify-between">
      <section>
        <h2 className="text-base font-semibold text-black">Preferences</h2>
        <div className="mt-6 w-full">
          {Object.entries(preferencesData).map(([key, value]) => (
            <div key={key} className="flex gap-4 mt-4 text-sm items-center">
              <div className="w-44 font-medium text-black">
                {key.charAt(0).toUpperCase() + key.slice(1)} for payments
              </div>
              <div className="flex w-full items-center justify-between bg-white border border-slate-300 rounded-md p-2">
                <span>{value}</span>
                <img
                  loading="lazy"
                  src={arrowDown}
                  className="w-4 h-4 ml-2"
                  alt=""
                />
              </div>
            </div>
          ))}
          <div className="flex items-center gap-4 mt-4">
            <div className="w-44 text-sm font-medium text-black">KYB</div>
            <div className="flex items-center">
              <button
                onClick={toggleSwitch}
                className="w-11 flex items-center bg-slate-900 rounded-full p-1 transition-colors duration-300"
                aria-checked={isOn}
                aria-label="KYB toggle switch"
                role="switch"
              >
                <div
                  className={`w-5 h-5 bg-white rounded-full transition-transform ${
                    isOn ? 'translate-x-4' : ''
                  }`}
                />
              </button>
              <span className="ml-2 text-sm font-medium text-black">
                {isOn ? 'ON' : 'OFF'}
              </span>
            </div>
          </div>
        </div>
      </section>

      <div className="self-end">
        <button className="px-4 py-2 bg-slate-900 text-white rounded-md">
          Save Changes
        </button>
      </div>
    </section>
  );
};

export default PreferencesSection;
