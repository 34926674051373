import React from 'react';

const Profile: React.FC = () => {
  const formFields = [
    {
      label: 'Profile Name',
      id: 'profileName',
      type: 'text',
      value: 'Mark Tomson',
    },
    {
      label: 'Email',
      id: 'email',
      type: 'email',
      value: 'mark.tompson@gmail.com',
    },
    { label: 'Password', id: 'password', type: 'password', value: '*********' },
  ];

  return (
    <section className="flex flex-col py-2 justify-between">
      <section>
        <h1 className="text-base font-semibold">Profile</h1>

        <form className="flex flex-col mt-2 text-sm">
          {formFields.map((field) => (
            <div key={field.id} className="flex items-center mt-4">
              <label htmlFor={field.id} className="font-medium w-1/3">
                {field.label}
              </label>
              <input
                type={field.type}
                id={field.id}
                value={field.value}
                className="py-2 px-3 w-full border bg-white border-slate-300 rounded-md"
                readOnly
              />
            </div>
          ))}
          <button
            type="button"
            className="mt-4 py-2 px-4 bg-slate-100 text-slate-900 rounded-md"
          >
            Change password
          </button>
        </form>

        <section className="mt-6">
          <h2 className="font-medium">Delete account</h2>
          <p className="mt-1 text-slate-700">
            Permanently removes your account and all data. Ensure you have
            backed up important information. This action cannot be undone.
          </p>
          <button className="mt-4 py-2 px-4 bg-red-500 text-white rounded-md">
            Delete account
          </button>
        </section>
      </section>

      <div className="self-end">
        <button className="py-2 px-4 bg-slate-900 bg-opacity-20 rounded-md">
          Save changes
        </button>
      </div>
    </section>
  );
};

export default Profile;
