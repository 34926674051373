import { BigNumber, formatFixed, parseFixed } from '@ethersproject/bignumber';

export const weiToUnit = (value: BigNumber, decimals = 18) => {
  // const result = value.mul(10000).div(BigNumber.from(10).pow(decimals));
  // return result.toNumber() / 10000;

  if (!value?._isBigNumber) {
    console.warn(`[weiToUnit] ${value} is not of type BigNumber`);
    return;
  }

  return parseFloat(formatFixed(value, decimals));
};

export const unitToWei = (value: string, decimals = 18) => {
  if (!(parseFloat(value) > 0)) {
    return BigNumber.from(0);
  }

  return parseFixed(
    value.length > decimals ? value.substr(0, decimals) : value,
    decimals
  );
};

export const extractDateMonthAndTime = (timestamp: any) => {
  if (timestamp === undefined || timestamp === null) {
    return 'Not Available Now';
  }

  const dateObj = new Date(timestamp);

  const date = dateObj.getDate();
  const month = dateObj.toLocaleString('en-US', { month: 'short' });
  const time = dateObj.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${date} ${month}, ${time}`;
};

export const extractNameAndTag = (
  input: string
): { name: string; tag: string | undefined } => {
  const delimiter = '--';
  const delimiterIndex = input.indexOf(delimiter);

  if (delimiterIndex === -1) {
    return {
      name: input,
      tag: undefined,
    };
  }

  const name = input.substring(0, delimiterIndex).trim();
  const tag = input.substring(delimiterIndex + delimiter.length).trim();

  return { name, tag };
};

export function formatWithdrawDate(isoDate: string): string {
  const date = new Date(isoDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedTime = `${hours}:${minutes} ${ampm}`;
  return `${day}/${month}/${year} ${formattedTime}`;
}
