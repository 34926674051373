import { more } from '../../assets';

interface Address {
  type: string;
  address: string;
  city: string;
}

const addresses: Address[] = [
  {
    type: 'Main Address',
    address: 'Here is the address',
    city: 'Bucharest',
  },
  {
    type: 'Main Address',
    address: 'Here is the address',
    city: 'Bucharest',
  },
];

const BillingDetails: React.FC = () => {
  return (
    <section className="py-2 w-full mx-auto">
      <header className="flex justify-between items-center mb-4">
        <h1 className="text-base font-semibold text-black">Billing Details</h1>
        <button className="px-4 py-2 text-sm font-medium text-white bg-slate-900 rounded-md">
          Add New
        </button>
      </header>

      {addresses.map((address, index) => (
        <article
          key={index}
          className="flex justify-between items-start p-4 mt-4 bg-white rounded-md border shadow-sm"
        >
          <div>
            <h2 className="font-semibold">{address.type}</h2>
            <p>{address.address}</p>
            <p className="text-xs text-slate-500">{address.city}</p>
          </div>
          <button
            className="w-8 h-8 flex justify-center items-center bg-white rounded-md border"
            aria-label="Edit address"
          >
            <img loading="lazy" src={more} className="w-4 h-4" alt="Edit" />
          </button>
        </article>
      ))}
    </section>
  );
};

export default BillingDetails;
