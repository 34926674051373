import React from 'react';
import withValidLogin from './hoc/withValidLogin';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { hooks as metaMaskHooks, metaMask } from './connectors/metamask';
import { ApiDataProvider } from './context/ApiDataProvider';
import { LoginProvider } from './context/LoginProvider';
import { ThirdwebAccountProvider } from './context/ThirdwebAccountProvider';
import { Route, Routes } from 'react-router-dom';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import { ThirdwebProvider } from 'thirdweb/react';
import { useAutoConnect } from 'thirdweb/react';
import { client } from './config/thirdwebConfig';
import { inAppWallet } from 'thirdweb/wallets';

import Home from './views/Home';
import { Login, Signup } from './views/Auth';
import Projects from './views/Projects';
import Project from './views/Project';
import { Sales } from './views/Sales';
import { Create } from './views/Sales/Create';
import { Contracts } from './views/Contracts';
import { Financials } from './views/Financials';
import { Settings } from './views/Settings';
import { AppProvider } from './context/AppProvider';
import { ToastContainer } from 'react-toastify';
import { chains } from './config/wagmiConfig';
import { ModalProvider } from './context/ModalProvider';
const ProjectWithAuth = withValidLogin(Project);
const ProjectsWithAuth = withValidLogin(Projects);
const SalesWithAuth = withValidLogin(Sales);
const CreateWithAuth = withValidLogin(Create);
const ContractsWithAuth = withValidLogin(Contracts);
const FinancialsWithAuth = withValidLogin(Financials);
const SettingsWithAuth = withValidLogin(Settings);

const projectId = '4f962eae4856f771802aaf869f880015';

const metadata = {
  name: 'G8WY',
  description: 'Real Estate, Unreal Speed',
  url: 'https://g8wy.app',
};

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-accent': 'linear-gradient(134.5deg, #C25FFF 2.88%, #3D29D0 98.85%)',
    '--w3m-z-index': 9999,
  },
});

const ThirdwebAutoConnect: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { data: autoConnected } = useAutoConnect({
    client,
    accountAbstraction: undefined,
    wallets: [inAppWallet()],
    onConnect: async (wallet) => {
      console.log(autoConnected, 'to', wallet);
    },
    timeout: 15000,
  });

  return <>{children}</>;
};

const Providers: React.FC<any> = ({ children }) => {
  const connectors: [MetaMask, Web3ReactHooks][] = [[metaMask, metaMaskHooks]];

  return (
    <ThirdwebProvider>
      <WagmiConfig config={wagmiConfig}>
        <Web3ReactProvider connectors={connectors}>
          <LoginProvider>
            <ThirdwebAccountProvider>
              <AppProvider>
                <ApiDataProvider>
                  <ModalProvider>{children}</ModalProvider>
                </ApiDataProvider>
              </AppProvider>
            </ThirdwebAccountProvider>
          </LoginProvider>
        </Web3ReactProvider>
      </WagmiConfig>
    </ThirdwebProvider>
  );
};

const App = () => {
  return (
    <Providers>
      <ThirdwebAutoConnect>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<ProjectsWithAuth />} />
          <Route path="/project" element={<ProjectWithAuth />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/sales" element={<SalesWithAuth />} />
          <Route path="/sales/create" element={<CreateWithAuth />} />
          <Route path="/contracts" element={<ContractsWithAuth />} />
          <Route path="/financials" element={<FinancialsWithAuth />} />
          <Route path="/settings" element={<SettingsWithAuth />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </ThirdwebAutoConnect>
    </Providers>
  );
};

export default App;
