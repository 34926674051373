import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useProfiles,
  useDisconnect,
  useActiveWallet,
  useWalletBalance,
} from 'thirdweb/react';
import { polygonAmoy } from 'thirdweb/chains';

import { maticLogo, settings, logout } from '../../assets';
import { obfuscateEmail, shortenAddress } from '../../utils';
import { client } from '../../config/thirdwebConfig';

interface UserMenuDropdownProps {
  userAddress: string;
}

const UserMenuDropdown: React.FC<UserMenuDropdownProps> = ({ userAddress }) => {
  const activeWallet = useActiveWallet();
  const { data: profiles } = useProfiles();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = React.useState<string>('');

  const { data: nativeBalance } = useWalletBalance({
    chain: polygonAmoy,
    address: userAddress,
    client,
  });

  useEffect(() => {
    if (profiles) {
      setUserEmail(profiles[0].details.email || '');
    }
  }, [profiles]);

  const menuItems = [
    {
      icon: settings,
      text: 'Settings',
      shortcut: '',
      onClick: () => {
        navigate('/settings');
      },
    },
    {
      icon: logout,
      text: 'Log out',
      shortcut: '⌘⇧Q',
      onClick: () => {
        if (activeWallet) disconnect(activeWallet);
      },
    },
  ];

  return (
    <nav className="absolute top-12 flex flex-col items-center rounded-md shadow-sm border border-slate-100 w-[214px]">
      <header className="p-2 w-full bg-white border-b border-slate-100">
        <div className="flex gap-1 items-center">
          <div className="flex flex-col">
            <span className="text-xs text-slate-900 whitespace-nowrap">
              {userEmail.length > 0
                ? obfuscateEmail(userEmail)
                : shortenAddress(userAddress)}
            </span>
            <div className="flex items-center gap-1 text-sm font-semibold text-black">
              <img loading="lazy" src={maticLogo} alt="" className="w-4" />
              <span>{`${nativeBalance?.displayValue.slice(0, 4) || '0'} MATIC`}</span>
            </div>
          </div>
        </div>
      </header>

      {menuItems.map((item, index) => (
        <button
          key={index}
          className="flex items-center gap-2 p-2 w-full text-sm bg-white hover:bg-gray-100 border-b border-slate-100"
          onClick={item.onClick}
        >
          <img loading="lazy" src={item.icon} alt={item.text} className="w-4" />
          <span className=" text-slate-700">{item.text}</span>
          {item.shortcut && (
            <span className="text-xs text-slate-500 flex-1 text-end">
              {item.shortcut}
            </span>
          )}
        </button>
      ))}
    </nav>
  );
};

export default UserMenuDropdown;
